import {useEffect} from 'react';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {Layouter} from '../layouter.tsx';
import {
  type EditorSelectionStore,
  createEditorSelectionSlice,
} from './editor-selection.ts';
import {
  type ProzessStore,
  createProzessWithAenderungenSlice,
} from './prozess-aenderungen.ts';

export const useProzessEditorStore = create<
  ProzessStore & EditorSelectionStore
>()(
  immer((...a) => ({
    ...createProzessWithAenderungenSlice(...a),
    ...createEditorSelectionSlice(...a),
  })),
);

const layouter = new Layouter();

/**
 * Updates the layout information of each node asynchronously as a side-effect after every relevant change to the Prozess.
 */
export const useProzessEditorAutoLayout = () => {
  useEffect(() => {
    const unsubscribe = useProzessEditorStore.subscribe(async (curr, prev) => {
      // WARN: The layoutVersion is incremented on every layout-relevant change (CRUD of nodes and connections)
      // this ensures that we only layout when necessary and prevent endless loops when setting the state below.
      if (curr.prozess.layoutVersion === prev.prozess.layoutVersion) return;

      const layoutInfo = curr.prozess.isLayoutable
        ? await layouter.layout(
            curr.prozess.schritte,
            curr.prozess.startId,
            curr.prozess.verbindungen,
          )
        : null;

      if (!layoutInfo) return;

      useProzessEditorStore.setState((state) => {
        for (const schritt of state.prozess.schritte) {
          const layout = layoutInfo?.get(schritt.id);

          if (layout) {
            schritt.layout = {
              ...schritt.layout,
              ...layout,
            };
          }
        }
      });
    });

    return () => {
      unsubscribe();
    };
  }, []);
};
