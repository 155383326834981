import {create} from 'zustand';

type State = {
  antragsartFilter: string;
};

type Action = {
  updateAntragsartFilter: (antragsArtFilter: State['antragsartFilter']) => void;
};

export const useAppUiStore = create<State & Action>((set) => ({
  antragsartFilter: '',
  updateAntragsartFilter: (antragsartFilter) =>
    set(() => ({antragsartFilter: antragsartFilter})),
}));
