import {useFormBuilder} from '@atmina/formbuilder';
import * as Dialog from '@radix-ui/react-dialog';
import {format, parseJSON} from 'date-fns';
import {type FC, useMemo, useState} from 'react';
import {MdKeyboardBackspace} from 'react-icons/md';
import SVG from 'react-inlinesvg';
import {useNavigate, useParams} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {AusfuehrungStatus} from '../../../__generated__/graphql.ts';
import ConvertToText from '../../../assets/convert_to_text.svg';
import MoveLocation from '../../../assets/move_location.svg';
import {Accordion} from '../../../components/accordion.tsx';
import {CheckboxListFormField} from '../../../components/form/fields/checkbox-field';
import {InputFormField} from '../../../components/form/fields/input-field';
import {Form} from '../../../components/form/form.tsx';
import {Button} from '../../../components/ui/button';
import {
  type SlotValue_EncryptedSlotValue_Fragment,
  type SlotValue_PlainSlotValue_Fragment,
} from '../../../components/ui/slot-value/slot-value.generated.ts';
import {useAuth} from '../../../lib/context/auth/auth.tsx';
import {mitarbeiterIsInGruppe} from '../../../lib/context/auth/mitarbeiter-is-in-gruppe.ts';
import {CryptoProvider} from '../../../lib/context/crypto/crypto.tsx';
import {symmetricEncryptValue} from '../../../lib/crypto/crypto.tsx';
import {useCryptoCache} from '../../../lib/hooks/use-crypto-cache.ts';
import {useDecryptedKey} from '../../../lib/hooks/use-decrypted-key.ts';
import {AusfuehrungWeiterleiten} from '../components/ausfuehrung-weiterleiten/ausfuehrung-weiterleiten.tsx';
import {useDecryptedSlots} from '../utils.ts';
import {BezahlenSchritt} from './components/schritte/bezahlen-schritt.tsx';
import {EmailForwarderExportSchritt} from './components/schritte/email-forwarder-export-schritt.tsx';
import {FormularSchritt} from './components/schritte/formular-schritt.tsx';
import {
  type AntragDetailAusfuehrung,
  type AntragDetailSchritt,
  type SlotValuesInput,
  useAusfuehrungContinueMutation,
  useAusfuehrungQuery,
  useAusfuehrungUpdateZugewiesenePersonMutation,
} from './detail.generated.ts';

export const AntragDetail: FC = () => {
  const {id = ''} = useParams();
  const {user} = useAuth();
  const [{data}] = useAusfuehrungQuery({variables: {ausfuehrungId: id}});

  const [weiterleitenModalOpen, setWeiterleitenModalOpen] = useState(false);

  const [ausfuehrungContinueState, ausfuehrungContinue] =
    useAusfuehrungContinueMutation();
  const [, ausfuehrungUpdateZugewiesenePerson] =
    useAusfuehrungUpdateZugewiesenePersonMutation();
  const cryptoCache = useCryptoCache();

  const navigate = useNavigate();

  const ausfuehrung = data?.ausfuehrung;
  const formbuilder = useFormBuilder<Record<string, string | null>>({
    values:
      ausfuehrung?.currentSchritt.__typename === 'FormularSchritt'
        ? Object.fromEntries(
            ausfuehrung.currentSchritt.felder.map(({slotName}) => [
              slotName,
              null,
            ]),
          )
        : {},
  });

  const decryptionKey = useDecryptedKey(
    ausfuehrung?.sharedKeyEncryptedForKommune,
    cryptoCache,
  );

  const currentSchritt = ausfuehrung?.currentSchritt;

  const schrittMitarbeiterGruppe = currentSchritt?.mitarbeiterGruppe?.id;
  const userMitarbeiterGruppen =
    user?.__typename == 'BackofficeUser' ? user.gruppen.map((g) => g.id) : [];

  const kannAntragUebernehmen =
    schrittMitarbeiterGruppe == undefined
      ? true
      : userMitarbeiterGruppen.includes(schrittMitarbeiterGruppe);

  const slots = useDecryptedSlots(
    cryptoCache,
    decryptionKey,
    ausfuehrung?.slotValues,
  );

  const namedVerbindungen = useMemo(() => {
    if (!slots) return [];

    return (
      currentSchritt?.verbindungen
        .filter((v) => !!v.name)
        .filter((v) => v.istRelevant.evaluate(slots)) ?? []
    );
  }, [currentSchritt?.verbindungen, slots]);

  if (!ausfuehrung || !decryptionKey || !currentSchritt) {
    return null;
  }

  const submitFormSchritt = async (form: Record<string, string | null>) => {
    const encryptionPromises: Promise<SlotValuesInput>[] = Object.entries(
      form,
    ).map(async ([key, value]) => ({
      name: key,
      value: {
        encryptedValue: await symmetricEncryptValue(
          decryptionKey,
          value ?? '',
          [],
        ),
      },
    }));

    return await Promise.all(encryptionPromises);
  };

  const handleClickVerbindung = async (verbindungId: string) => {
    const slotValues = await submitFormSchritt(formbuilder.getValues());

    await ausfuehrungContinue({
      ausfuehrungId: ausfuehrung.id,
      bearbeitungen: [
        {
          schrittId: currentSchritt.id,
          nextVerbindungId: verbindungId,
          slotValues: slotValues,
        },
      ],
    });
  };

  const currentSchrittIsActionable =
    currentSchritt.__typename === 'FormularSchritt' &&
    !currentSchritt?.erledigungDurchBuerger &&
    currentSchritt.felder.length > 0;

  return (
    <CryptoProvider decryptionKey={decryptionKey} cryptoCache={cryptoCache}>
      <div className='container mt-8 flex h-full flex-col text-neutral-600'>
        <div
          className={twMerge(
            'relative mb-4 flex justify-between',
            currentSchrittIsActionable ? 'flex-col' : 'items-start',
          )}
        >
          <div className='mt-2 flex w-full flex-col gap-3'>
            <div className='flex items-center gap-3'>
              <MdKeyboardBackspace
                className='size-8 hover:cursor-pointer'
                onClick={() => navigate(-1)}
              />
              <h1 className='text-3xl font-bold leading-10'>
                {ausfuehrung.prozessVersion.prozess.name}
              </h1>
            </div>
            {ausfuehrung.manuellAbgeschlossen && (
              <div className='font-bold text-primary'>
                Dieser Antrag wurde über die Weiterleitung zur Abgabe per E-Mail
                am {ausfuehrung.manuellAbgeschlossen.toLocaleDateString()}{' '}
                abgeschlossen.
              </div>
            )}
            <div className=' flex flex-col gap-1 sm:flex-row sm:items-center sm:gap-2'>
              <div className='text-sm font-bold leading-snug'>
                {format(parseJSON(ausfuehrung.createdAt), 'dd.MM.yyyy')}
              </div>
              {ausfuehrung.zugewiesenePerson && (
                <>
                  <div className='w-px self-stretch bg-slate-300' />
                  <div>
                    Bearbeiter: {ausfuehrung.zugewiesenePerson.vorname}{' '}
                    {ausfuehrung.zugewiesenePerson.nachname}
                  </div>
                </>
              )}
              {ausfuehrung.zugewiesenePerson?.id !== user?.id && (
                <>
                  <div className='w-px self-stretch bg-slate-300' />
                  <div
                    className={
                      kannAntragUebernehmen
                        ? 'fill-primary text-primary'
                        : 'fill-gray-400 text-gray-400  opacity-60'
                    }
                  >
                    <button
                      title={
                        kannAntragUebernehmen
                          ? undefined
                          : 'Sie haben nicht die benötigten Gruppenberechtigungen um den Antrag übernehmen zu können.'
                      }
                      disabled={!kannAntragUebernehmen}
                      onClick={async () => {
                        await ausfuehrungUpdateZugewiesenePerson({
                          ausfuehrungId: ausfuehrung.id,
                          userId: user?.id ?? '',
                        });
                      }}
                      className='flex items-center text-start'
                    >
                      <SVG
                        src={MoveLocation}
                        className='mr-1  size-6 shrink-0'
                      />
                      <div>Bearbeitung übernehmen</div>
                    </button>
                  </div>
                </>
              )}
              {ausfuehrung.status != AusfuehrungStatus.Abgeschlossen && (
                <>
                  <div className='w-px self-stretch bg-slate-300' />
                  <Dialog.Root
                    open={weiterleitenModalOpen}
                    onOpenChange={setWeiterleitenModalOpen}
                  >
                    <Dialog.Trigger>
                      <button className='flex gap-1 text-primary'>
                        <SVG src={ConvertToText} />
                        Antrag weiterleiten
                      </button>
                    </Dialog.Trigger>
                    <AusfuehrungWeiterleiten
                      onOpenChange={setWeiterleitenModalOpen}
                      currentSchrittAbteilungId={
                        ausfuehrung.currentSchritt.mitarbeiterGruppe?.id ?? null
                      }
                      ausfuehrungId={ausfuehrung.id}
                    />
                  </Dialog.Root>
                </>
              )}
            </div>
            {currentSchrittIsActionable && (
              <Form builder={formbuilder} className='mt-2 w-1/2'>
                {currentSchritt.felder.map((feld) => {
                  if (feld.__typename == 'StringFormularFeld') {
                    return (
                      <InputFormField
                        key={feld.id}
                        multiLine={
                          feld.__typename == 'StringFormularFeld'
                            ? feld.multiline
                            : false
                        }
                        on={formbuilder.fields[feld.slotName]}
                        label={feld.angezeigterName ?? feld.slotName}
                      />
                    );
                  } else if (feld.__typename === 'CheckboxListFormularFeld') {
                    return (
                      <CheckboxListFormField
                        key={feld.slotName}
                        options={feld.options}
                        on={formbuilder.fields[feld.slotName]}
                        label={feld.angezeigterName ?? feld.slotName}
                      />
                    );
                  }
                })}
              </Form>
            )}
            <div
              className={twMerge(
                'my-4 flex flex-wrap justify-start gap-x-3 gap-y-4 rounded-lg bg-gray-100 p-4',
                currentSchrittIsActionable && 'ml-auto mr-[50%]',
              )}
            >
              {namedVerbindungen.map((v) => {
                const userIsAssigned =
                  user &&
                  currentSchritt.mitarbeiterGruppe &&
                  mitarbeiterIsInGruppe(
                    user,
                    currentSchritt.mitarbeiterGruppe.id,
                  );

                return (
                  <Button
                    key={v.id}
                    disabled={
                      !userIsAssigned || ausfuehrungContinueState.fetching
                    }
                    onClick={() => handleClickVerbindung(v.id!)}
                    title={
                      !userIsAssigned
                        ? `Erfordert Mitarbeitergruppe: ${currentSchritt.mitarbeiterGruppe?.name}`
                        : undefined
                    }
                  >
                    {v.name}
                  </Button>
                );
              })}
            </div>
            {currentSchrittIsActionable && (
              <div className='my-4 flex items-center gap-2'>
                <h2 className='text-xl font-bold'>Antragsdaten</h2>
                <div className='h-px w-full bg-gray-300' />
              </div>
            )}
          </div>
        </div>
        <div className='flex flex-col items-start gap-4'>
          {ausfuehrung.prozessVersion.vergangeneSchritte
            .filter((s) => s.schritt.titel && s.schritt.id != currentSchritt.id)
            .map((s, index) => {
              const mitarbeiter =
                s.bearbeitenderUser?.__typename === 'BackofficeUser'
                  ? s.bearbeitenderUser
                  : undefined;
              const bearbeitenderUser = mitarbeiter
                ? `${mitarbeiter.vorname.charAt(0)}. ${mitarbeiter.nachname}`
                : undefined;

              return (
                <ProzessSchritt
                  key={s.schritt.id}
                  schritt={s.schritt}
                  bearbeitungsDatum={s.bearbeitungsDatum ?? undefined}
                  bearbeitenderUser={bearbeitenderUser}
                  ausfuehrung={ausfuehrung}
                  index={index}
                />
              );
            })}
        </div>
      </div>
    </CryptoProvider>
  );
};

type ProzessSchrittProps = {
  bearbeitungsDatum?: Date;
  bearbeitenderUser?: string;
  schritt: AntragDetailSchritt;
  ausfuehrung: AntragDetailAusfuehrung;
  index: number;
};

const ProzessSchritt: FC<ProzessSchrittProps> = (props) => {
  const slotValuesDict: {
    [key: string]:
      | SlotValue_EncryptedSlotValue_Fragment
      | SlotValue_PlainSlotValue_Fragment;
  } = {};
  props.ausfuehrung.slotValues.forEach(
    (sv) => (slotValuesDict[sv.slotName] = sv),
  );

  const slotsBenoetigenFreigabe = props.schritt.slots.filter(
    (s) => s.brauchtFreigabe && !slotValuesDict[s.name].istFreigegeben,
  );

  return (
    <Accordion
      heading={
        <div className='flex w-full items-center justify-between gap-4 text-xl font-bold'>
          <div className='flex items-baseline gap-4'>
            <div>
              {props.index + 1}. {props.schritt.titel}
            </div>
            {slotsBenoetigenFreigabe.length > 0 && (
              <p className='rounded-lg bg-red-200 px-2 py-1 text-sm font-medium text-red-500'>
                Benötigt Freigabe
              </p>
            )}
          </div>
          {props.bearbeitenderUser && (
            <p className='rounded-full bg-gray-100 px-2 py-1 text-sm font-medium'>
              {props.bearbeitenderUser}
            </p>
          )}
        </div>
      }
    >
      <div className='pt-2' key={props.schritt.id}>
        {props.schritt.__typename === 'FormularSchritt' && (
          <div>
            <FormularSchritt
              schritt={props.schritt}
              ausfuehrung={props.ausfuehrung}
            />
          </div>
        )}
        {props.schritt.__typename === 'BezahlenSchritt' && (
          <BezahlenSchritt schritt={props.schritt} />
        )}
        {props.schritt.__typename === 'EmailForwarderExportSchritt' && (
          <EmailForwarderExportSchritt
            bearbeitungsDatum={props.bearbeitungsDatum}
            schritt={props.schritt}
            ausfuehrung={props.ausfuehrung}
          />
        )}
      </div>
    </Accordion>
  );
};
