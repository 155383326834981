import {
  useMemo,
  type FC,
  type ReactNode,
  type RefAttributes,
  forwardRef,
  type ElementRef,
} from 'react';
import {
  MdAccountCircle,
  MdArrowDropDown,
  MdDashboardCustomize,
  MdHomeWork,
  MdLogout,
  MdMenu,
  MdOutlineEditNote,
} from 'react-icons/md';
import {RiShieldKeyholeLine} from 'react-icons/ri';
import {Link, type LinkProps} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {hasFunktionsberechtigungInAnyOrganisationseinheit} from 'src/lib/funktionsberechtigung.ts';
import {
  FunktionsberechtigungInOrganisationseinheit,
  GlobaleFunktionsberechtigung,
} from '../../__generated__/graphql.ts';
import {useAuth} from '../../lib/context/auth/auth.tsx';
import {DropdownMenu} from '../ui/dropdown-menu/dropdown-menu.tsx';

export const mainMenuItems: {
  label: string;
  href: string;
  icon: ReactNode;
  external?: boolean;
  funktionsberechtigung?:
    | GlobaleFunktionsberechtigung
    | FunktionsberechtigungInOrganisationseinheit;
}[] = [
  {
    label: 'Nutzendenverwaltung',
    href: '/nutzendenverwaltung',
    icon: <MdAccountCircle />,
    funktionsberechtigung:
      FunktionsberechtigungInOrganisationseinheit.BackofficeNutzendenverwaltung,
  },
  {
    label: 'Inhaltspflege',
    href: '/cms',
    icon: <MdDashboardCustomize />,
    external: true,
    funktionsberechtigung:
      FunktionsberechtigungInOrganisationseinheit.Inhaltspflege,
  },
  {
    label: 'Prozessverwaltung',
    href: '/prozessverwaltung',
    icon: <MdOutlineEditNote />,
    funktionsberechtigung: GlobaleFunktionsberechtigung.Prozessverwaltung,
  },
];

export const NavMenu = () => {
  const {logout, user} = useAuth();

  const links = useMemo(
    () =>
      mainMenuItems.filter((link) =>
        hasFunktionsberechtigungInAnyOrganisationseinheit(
          user,
          link.funktionsberechtigung,
        ),
      ),
    [user],
  );

  return (
    <div className='ml-auto flex gap-4 justify-self-end'>
      <UserMenu />
      {links.length > 0 && (
        <>
          <VerticalDivider />
          <div className='relative flex h-full items-center'>
            <DropdownMenu>
              <DropdownMenu.Trigger>
                <MdMenu className='size-8 hover:cursor-pointer' />
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <DropdownMenu.Content>
                  {links.map(({label, href, external, icon}) => (
                    <DropdownMenu.Item key={label} asChild>
                      <MenuItem to={href} external={external}>
                        <span>{icon}</span>
                        <span>{label}</span>
                      </MenuItem>
                    </DropdownMenu.Item>
                  ))}
                  <DropdownMenu.Arrow />
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu>
          </div>
        </>
      )}
      <VerticalDivider />
      <button
        type='button'
        className='flex items-center text-primary'
        onClick={() => logout()}
      >
        Abmelden
        <MdLogout className='ml-2 inline-block size-6' />
      </button>
    </div>
  );
};

export const UserMenu: FC<{mobile?: boolean; className?: string}> = ({
  className,
}) => {
  const {user} = useAuth();

  const vorname = user?.vorname;
  const nachname = user?.nachname;

  const organisationseinheiten =
    user && 'organisationseinheiten' in user ? user.organisationseinheiten : [];

  const organisationseinheitTextShort =
    organisationseinheiten.length === 1
      ? organisationseinheiten[0].meta.name
      : `${organisationseinheiten.length} Organisationseinheiten`;

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <div
          className={twMerge(
            'flex items-center gap-2 hover:cursor-pointer',
            className,
          )}
        >
          <MdAccountCircle className='my-auto size-10 text-primary' />
          <div className='flex flex-col'>
            {vorname && nachname && (
              <p className='font-bold'>{`${vorname.at(0)}. ${nachname}`}</p>
            )}
            <div className='text-xs'>{organisationseinheitTextShort}</div>
          </div>
          <MdArrowDropDown />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content>
          {organisationseinheiten.length > 1 && (
            <div className='mb-2 flex items-start gap-2 border-b border-gray-100 px-5 pb-2 text-gray-400'>
              <MdHomeWork className='mt-1' />
              <div className='whitespace-pre-wrap'>
                {organisationseinheiten.map((o) => o.meta.name).join('\n')}
              </div>
            </div>
          )}
          <DropdownMenu.Item asChild>
            <Link
              to='/profile/reset-password'
              className='flex items-center gap-2'
            >
              <RiShieldKeyholeLine />
              Passwort zurücksetzen
            </Link>
          </DropdownMenu.Item>
          <DropdownMenu.Arrow />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
};

const VerticalDivider: FC = () => (
  <hr className='my-auto h-8 w-[1px] bg-gray-200' />
);

const MenuItem = forwardRef<
  ElementRef<typeof Link>,
  LinkProps & RefAttributes<HTMLAnchorElement> & {external?: boolean}
>(({to, external, children, ...props}, ref) => (
  <Link
    {...props}
    ref={ref}
    to={to}
    className='group relative flex cursor-pointer select-none items-center gap-2 rounded-[3px] px-5 py-2 leading-none text-gray-600 outline-none hover:bg-primary/15 data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
    reloadDocument={external}
  >
    {children}
  </Link>
));

MenuItem.displayName = 'MenuItem';
