import {useFormBuilder} from '@atmina/formbuilder';
import {type FC, useCallback, useEffect, useMemo, useState} from 'react';
import {MdOutlineBackspace} from 'react-icons/md';
import {CheckboxFormField} from 'src/components/form/fields/checkbox-field/checkbox-field.tsx';
import {useConfirm} from 'src/components/modal/confirm-modal.tsx';
import {Form} from '../../../../components/form/form.tsx';
import {Sidebar} from '../../../../components/sidebar/sidebar.tsx';
import {Button} from '../../../../components/ui/button';
import {useProzessEditorStore} from '../lib/store';
import {type SchrittDaten} from '../lib/store/prozess-aenderungen.ts';
import {useMitarbeiterGruppenQuery} from '../prozess-editor.generated.ts';
import {BearbeitendeGruppeSelect} from './form/bearbeitende-gruppe-select.tsx';
import {EmailWeiterleitenSchritt} from './form/email-weiterleiten-schritt.tsx';
import {FormularSchrittForm} from './form/formular-schritt-form.tsx';
import {GovManagerRestExportSchritt} from './form/govmanager-export-schritt.tsx';
import {SidebarHeader} from './sidebar-header.tsx';

type ProzessSchrittForm = SchrittDaten;

export const ProzessSidebar: FC = () => {
  const currentProzess = useProzessEditorStore((state) => state.prozess);
  const deleteSchritt = useProzessEditorStore((state) => state.deleteSchritt);
  const selectedSchritt = useProzessEditorStore(
    (state) => state.selectedSchritt,
  );

  const [{data}] = useMitarbeiterGruppenQuery();

  const abteilungen = useMemo(() => {
    if (data?.my.__typename !== 'BackofficeUser') return [];

    return data.my.kommune.mitarbeiterGruppen;
  }, [data]);

  const abteilungenWithoutEmailIds = abteilungen
    .filter((a) => !a.eMailAdresse)
    .map((a) => a.id);

  const [subFormIsValid, setSubFormIsValid] = useState(true);

  const builder = useFormBuilder<ProzessSchrittForm>({
    values: selectedSchritt ?? undefined,
  });
  const {formState} = builder;

  const updateSchritt = useProzessEditorStore((state) => state.updateSchritt);
  const handleSubmit = useCallback(
    (form: ProzessSchrittForm) => {
      if (!selectedSchritt?.id) return;
      updateSchritt(selectedSchritt.id, form);
    },
    [updateSchritt, selectedSchritt?.id],
  );

  const selectedAbteilungId = builder.fields.mitarbeiterGruppe.id.$useWatch();

  const versendeEmailAnMitarbeiterGruppe =
    builder.fields.versendeEMailAnMitarbeiterGruppe.$useWatch();

  const cannotSendMail =
    selectedAbteilungId == null ||
    abteilungenWithoutEmailIds.includes(selectedAbteilungId);
  const invalidCheckboxValue =
    versendeEmailAnMitarbeiterGruppe && cannotSendMail;

  useEffect(() => {
    if (invalidCheckboxValue) {
      builder.setValue('versendeEMailAnMitarbeiterGruppe', false);
    }
  }, [invalidCheckboxValue, builder]);

  const [__typename, narrowed] = builder.fields.$discriminate('__typename');
  const isValid = formState.isValid;

  const confirm = useConfirm();

  const onDeleteSchritt = useCallback(async () => {
    if (!selectedSchritt) return;

    const confirmed = await confirm({
      content: 'Sind Sie sicher, dass Sie diesen Schritt löschen möchten?',
      confirmText: 'Löschen',
      confirmVariant: 'destructive',
    });

    if (!confirmed) return;

    deleteSchritt(selectedSchritt.id);
  }, [confirm, deleteSchritt, selectedSchritt]);

  return (
    <Sidebar isOpen={!!selectedSchritt}>
      <Form
        builder={builder}
        onSubmit={handleSubmit}
        className='flex grow flex-col overflow-hidden'
      >
        <SidebarHeader
          on={builder.fields.titel}
          placeholder='Formular Schritt'
        />
        <div className='flex h-full w-[480px] flex-1 flex-col gap-4 overflow-auto p-4'>
          <p>Zuständigkeit für diesen Prozessknoten zuweisen</p>
          <BearbeitendeGruppeSelect on={builder.fields.mitarbeiterGruppe.id} />
          {selectedAbteilungId != null && (
            <CheckboxFormField
              on={builder.fields.versendeEMailAnMitarbeiterGruppe}
              title={
                cannotSendMail
                  ? 'Zu dieser Abteilung ist keine E-Mail-Adresse hinterlegt.'
                  : undefined
              }
              disabled={cannotSendMail}
              label='EMail Benachrichtigung'
            />
          )}
          <div className='flex flex-col'>
            {__typename === 'FormularSchritt' && (
              <FormularSchrittForm on={narrowed} />
            )}
            {__typename === 'GovManagerRestExportSchritt' && (
              <GovManagerRestExportSchritt
                subFormIsValid={(value: boolean) => setSubFormIsValid(value)}
                on={narrowed}
                storeProzess={currentProzess}
              />
            )}
            {__typename === 'EmailForwarderExportSchritt' && (
              <EmailWeiterleitenSchritt on={narrowed} />
            )}
          </div>
          <Button variant='solid' disabled={!subFormIsValid || !isValid}>
            Übernehmen
          </Button>
          <Button type='button' variant='destructive' onClick={onDeleteSchritt}>
            <div className='flex flex-col'>
              <div>Schritt löschen</div>
              <div className='text-xs text-red-200'>
                oder mit <MdOutlineBackspace className='mb-px inline-block' />{' '}
                Backspace
              </div>
            </div>
          </Button>
        </div>
      </Form>
    </Sidebar>
  );
};
