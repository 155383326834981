import {type Draft} from 'immer';
import {type StateCreator} from 'zustand';
import {
  deepUnparseExpressions,
  type WithUnparsedExpressions,
} from '@expressions/deep-unparse-expressions.ts';
import {type DistributiveOmit} from '../../../../../utils/common-types.ts';
import {type ProzessEditorVerbindungFragment} from '../../prozess-editor-verbindung.generated.ts';
import {type ProzessEditorSchritt} from '../../prozess-version-erstellen.generated.ts';
import {type ProzessEditorProzess} from '../../verfuegbare-prozesse.generated.ts';
import {type NodeLayout} from '../layouter.tsx';

export type SchrittDaten = WithUnparsedExpressions<ProzessEditorSchritt> & {
  layout?: NodeLayout;
};

export type VerbindungDaten =
  WithUnparsedExpressions<ProzessEditorVerbindungFragment>;

export type StoreProzess = {
  id: string;
  layoutVersion: number;
  name: string;
  startId: string;
  schritte: SchrittDaten[];
  verbindungen: VerbindungDaten[];
  latestVersionId?: string;
  isLayoutable: boolean;
  isComplete: boolean;
};

export type ProzessStore = {
  prozess: StoreProzess;
  hasAenderungen: boolean;

  initializeFromProzess: (
    prozess: StoreProzess | null,
    layoutVersion?: number,
  ) => void;

  createSchritt: (
    data: DistributiveOmit<SchrittDaten, 'id'>,
    layoutInfo?: NodeLayout,
  ) => SchrittDaten;
  updateSchritt: (id: string, data: Partial<SchrittDaten>) => void;
  updateSchrittPosition: (id: string, position: NodeLayout['position']) => void;
  deleteSchritt: (id: string) => void;

  createVerbindung: (data: Omit<VerbindungDaten, 'id'>) => VerbindungDaten;
  updateVerbindung: (id: string, data: Partial<VerbindungDaten>) => void;
  deleteVerbindung: (id: string) => void;
};

const emptyProzess: StoreProzess = {
  id: '',
  layoutVersion: 0,
  startId: '',
  name: 'Neuer Prozess',
  schritte: [],
  verbindungen: [],
  isLayoutable: false,
  isComplete: false,
};

export const dataToStoreProzess = (
  prozess: ProzessEditorProzess,
): StoreProzess => {
  const schritte = prozess.latestVersion?.alleSchritte ?? [
    {
      id: crypto.randomUUID(),
      titel: 'Neuer Schritt',
      verbindungen: [],
      erledigungDurchBuerger: true,
      slots: [],
      mitarbeiterGruppe: null,
      versendeEMailAnMitarbeiterGruppe: false,
      anweisung: null,
      felder: [
        {
          id: crypto.randomUUID(),
          __typename: 'StringFormularFeld',
          angezeigterName: 'Neues Feld',
          multiline: false,
          istRelevant: 'true',
          options: [],
          slotName: 'Neuer Slot',
          order: 0,
          encrypted: false,
        },
      ],
      __typename: 'FormularSchritt',
    },
  ];
  return {
    id: prozess.id,
    layoutVersion: 1,
    latestVersionId: prozess.latestVersion?.id,
    startId: prozess.latestVersion?.start?.id ?? schritte[0].id,
    name: prozess.name,
    schritte: deepUnparseExpressions(schritte),
    verbindungen: deepUnparseExpressions(
      schritte.flatMap((s) => s.verbindungen.map((v) => ({...v}))),
    ),
    isLayoutable: true,
    isComplete: true,
  };
};

const copy = <T>(from: Partial<T>, to: T) => {
  for (const key in from) {
    const newValue = from[key];
    // Explicitly checking so we can update boolean valus to
    // otherwise the woule be scipped
    if (newValue !== undefined && newValue !== null) {
      to[key] = newValue;
    }
  }
};

/**
 * Check if the graph of a given prozess is complete.
 * In a complete graph, all nodes (except start and end) have at least one incoming and one outgoing connection.
 *
 * A graph is layoutable if it is continouous. It is complete if every leaf-node is of type "EndeSchritt".
 */
const updateGraphCompleteness = (prozessDraft: Draft<StoreProzess>) => {
  const startId = prozessDraft.startId;
  if (!startId) return {isLayoutable: false, isComplete: false};

  const graph: Record<string, string[]> = {};
  for (const verbindung of prozessDraft.verbindungen) {
    graph[verbindung.vonId] ??= [];
    graph[verbindung.vonId].push(verbindung.nachId);

    graph[verbindung.nachId] ??= [];
  }

  const schrittTypeMap = new Map<
    string,
    StoreProzess['schritte'][number]['__typename']
  >(prozessDraft.schritte.map((schritt) => [schritt.id, schritt.__typename]));

  const visited = new Set<string>();
  let allLeafPathsValid = true;

  const dfs = (node: string): void => {
    visited.add(node);
    const neighbors = graph[node] ?? [];

    // No outgoing edges -> this is a leaf node
    if (neighbors.length === 0) {
      if (schrittTypeMap.get(node) !== 'EndeSchritt') {
        allLeafPathsValid = false;
      }
    } else {
      for (const neighbor of neighbors) {
        if (!visited.has(neighbor)) {
          dfs(neighbor);
        }
      }
    }
  };

  dfs(startId);

  const totalNodes = Object.keys(graph).length;
  const isLayoutable = visited.size === totalNodes;
  prozessDraft.isLayoutable = isLayoutable;
  prozessDraft.isComplete = allLeafPathsValid;
};

export const createProzessWithAenderungenSlice: StateCreator<
  ProzessStore,
  [['zustand/immer', never]],
  [],
  ProzessStore
> = (set) => ({
  prozess: emptyProzess,
  hasAenderungen: false,

  initializeFromProzess: (prozess, layoutVersion) =>
    set((state) => {
      state.prozess = prozess ?? emptyProzess;
      if (layoutVersion !== undefined) {
        state.prozess.layoutVersion = layoutVersion;
      }
      state.hasAenderungen = false;
      updateGraphCompleteness(state.prozess);
    }),

  createSchritt: (data, layout) => {
    const newSchritt = {
      ...data,
      id: crypto.randomUUID(),
      layout: layout,
    } satisfies SchrittDaten;

    set((state) => {
      state.prozess.schritte.push(newSchritt);
      state.prozess.layoutVersion++;
      state.hasAenderungen = true;
    });

    return newSchritt;
  },
  updateSchritt: (id, data) => {
    set((state) => {
      const schritte = state.prozess.schritte;
      const target = schritte.find((s) => s.id === id);
      if (!target) return;

      if (Object.keys(data).length > 0) {
        copy(data, target);

        state.prozess.layoutVersion++;
        state.hasAenderungen = true;
        updateGraphCompleteness(state.prozess);
      }
    });
  },
  updateSchrittPosition: (id, position) => {
    set((state) => {
      const schritte = state.prozess.schritte;
      const target = schritte.find((s) => s.id === id);
      if (!target) return;

      target.layout = {
        ...target.layout,
        position,
      };
    });
  },
  deleteSchritt: (id) =>
    set((state) => {
      const schritte = state.prozess.schritte;
      const targetIndex = schritte.findIndex((s) => s.id === id);
      if (targetIndex > -1) {
        schritte.splice(targetIndex, 1);
        state.prozess.verbindungen = state.prozess.verbindungen.filter(
          (v) => v.vonId !== id && v.nachId !== id,
        );
        state.prozess.layoutVersion++;
        state.hasAenderungen = true;
        updateGraphCompleteness(state.prozess);
      }
    }),

  createVerbindung: (data) => {
    const newVerbindung = {...data, id: crypto.randomUUID()};

    set((state) => {
      state.prozess.verbindungen.push(newVerbindung);

      state.prozess.layoutVersion++;
      state.hasAenderungen = true;
      updateGraphCompleteness(state.prozess);
    });

    return newVerbindung;
  },
  updateVerbindung: (id, data) => {
    set((state) => {
      const target = state.prozess.verbindungen.find((v) => v.id === id);
      if (!target) return;

      if (Object.keys(data).length > 0) {
        copy(data, target);
        state.prozess.layoutVersion++;
        state.hasAenderungen = true;
        updateGraphCompleteness(state.prozess);
      }
    });
  },
  deleteVerbindung: (id) => {
    set((state) => {
      state.hasAenderungen = true;
      const verbindungen = state.prozess.verbindungen;
      const index = verbindungen.findIndex((v) => v.id === id);
      if (index > -1) {
        verbindungen.splice(index, 1);

        state.prozess.layoutVersion++;
        state.hasAenderungen = true;
        updateGraphCompleteness(state.prozess);
      }
    });
  },
});
