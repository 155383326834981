import * as Types from '../../../__generated__/graphql';

import { ProzessEditorSchritt_BezahlenSchritt_Fragment, ProzessEditorSchritt_EmailForwarderExportSchritt_Fragment, ProzessEditorSchritt_EndeSchritt_Fragment, ProzessEditorSchritt_FormularSchritt_Fragment, ProzessEditorSchritt_GovManagerRestExportSchritt_Fragment, ProzessEditorSchritt_ProgrammatischerSchritt_Fragment, ProzessEditorSchritt_WarteAufBezahlungAbgeschlossenSchritt_Fragment, ProzessEditorSchritt_WarteAufGovManagerSchritt_Fragment } from './prozess-editor-schritt.generated';
import { ProzessEditorProzessFragment } from './prozess-version-erstellen.generated';
import {
  BoolExpression,
  IntExpression,
  StringExpression,
} from '@expressions/expressions.ts';

import gql from 'graphql-tag';
import { ProzessEditorSchrittFragmentDoc } from './prozess-editor-schritt.generated';
import { ProzessEditorProzessFragmentDoc } from './prozess-version-erstellen.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProzessVersionVeroeffentlichenInput = {
  prozessVersionId: string;
};

export type ProzessVersionFragment = { __typename?: 'ProzessVersion', id: string, publishedAt?: Date | null, start?: { __typename?: 'BezahlenSchritt', id: string } | { __typename?: 'EmailForwarderExportSchritt', id: string } | { __typename?: 'EndeSchritt', id: string } | { __typename?: 'FormularSchritt', id: string } | { __typename?: 'GovManagerRestExportSchritt', id: string } | { __typename?: 'ProgrammatischerSchritt', id: string } | { __typename?: 'WarteAufBezahlungAbgeschlossenSchritt', id: string } | { __typename?: 'WarteAufGovManagerSchritt', id: string } | null, alleSchritte: Array<(
    { __typename?: 'BezahlenSchritt' }
    & ProzessEditorSchritt_BezahlenSchritt_Fragment
  ) | (
    { __typename?: 'EmailForwarderExportSchritt' }
    & ProzessEditorSchritt_EmailForwarderExportSchritt_Fragment
  ) | (
    { __typename?: 'EndeSchritt' }
    & ProzessEditorSchritt_EndeSchritt_Fragment
  ) | (
    { __typename?: 'FormularSchritt' }
    & ProzessEditorSchritt_FormularSchritt_Fragment
  ) | (
    { __typename?: 'GovManagerRestExportSchritt' }
    & ProzessEditorSchritt_GovManagerRestExportSchritt_Fragment
  ) | (
    { __typename?: 'ProgrammatischerSchritt' }
    & ProzessEditorSchritt_ProgrammatischerSchritt_Fragment
  ) | (
    { __typename?: 'WarteAufBezahlungAbgeschlossenSchritt' }
    & ProzessEditorSchritt_WarteAufBezahlungAbgeschlossenSchritt_Fragment
  ) | (
    { __typename?: 'WarteAufGovManagerSchritt' }
    & ProzessEditorSchritt_WarteAufGovManagerSchritt_Fragment
  )> };


export type GetProzessByIdQueryVariables = Types.Exact<{
  prozessId: string;
}>;


export type GetProzessByIdQuery = { __typename?: 'Query', my: { __typename?: 'BackofficeUser', kommune: { __typename?: 'Kommune', prozessById: (
        { __typename?: 'Prozess' }
        & ProzessEditorProzessFragment
      ) } } | { __typename?: 'Buerger' } | { __typename?: 'SystemUser' } };


export type MitarbeiterGruppenQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MitarbeiterGruppenQuery = { __typename?: 'Query', my: { __typename?: 'BackofficeUser', id: string, kommune: { __typename?: 'Kommune', mitarbeiterGruppen: Array<{ __typename?: 'MitarbeiterGruppe', id: string, name: string, eMailAdresse?: string | null }> } } | { __typename?: 'Buerger', id: string } | { __typename?: 'SystemUser', id: string } };


export type ProzessVersionVeroeffentlichenMutationVariables = Types.Exact<{
  input: ProzessVersionVeroeffentlichenInput;
}>;


export type ProzessVersionVeroeffentlichenMutation = { __typename?: 'Mutation', prozessVersionVeroeffentlichen: { __typename?: 'ProzessVersionVeroeffentlichenPayload', prozessVersion?: (
      { __typename?: 'ProzessVersion' }
      & ProzessVersionFragment
    ) | null } };


export const ProzessVersionFragmentDoc = gql`
    fragment ProzessVersion on ProzessVersion {
  id
  publishedAt
  start {
    id
  }
  alleSchritte {
    ...ProzessEditorSchritt
  }
}
    ${ProzessEditorSchrittFragmentDoc}`;
export const GetProzessByIdDocument = gql`
    query GetProzessById($prozessId: UUID!) {
  my {
    ... on BackofficeUser {
      kommune {
        prozessById(id: $prozessId) {
          ...ProzessEditorProzess
        }
      }
    }
  }
}
    ${ProzessEditorProzessFragmentDoc}`;

export function useGetProzessByIdQuery(options: Omit<Urql.UseQueryArgs<GetProzessByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetProzessByIdQuery, GetProzessByIdQueryVariables>({ query: GetProzessByIdDocument, ...options });
};
export const MitarbeiterGruppenDocument = gql`
    query MitarbeiterGruppen {
  my {
    id
    ... on BackofficeUser {
      kommune {
        mitarbeiterGruppen {
          id
          name
          eMailAdresse
        }
      }
    }
  }
}
    `;

export function useMitarbeiterGruppenQuery(options?: Omit<Urql.UseQueryArgs<MitarbeiterGruppenQueryVariables>, 'query'>) {
  return Urql.useQuery<MitarbeiterGruppenQuery, MitarbeiterGruppenQueryVariables>({ query: MitarbeiterGruppenDocument, ...options });
};
export const ProzessVersionVeroeffentlichenDocument = gql`
    mutation ProzessVersionVeroeffentlichen($input: ProzessVersionVeroeffentlichenInput!) {
  prozessVersionVeroeffentlichen(input: $input) {
    prozessVersion {
      ...ProzessVersion
    }
  }
}
    ${ProzessVersionFragmentDoc}`;

export function useProzessVersionVeroeffentlichenMutation() {
  return Urql.useMutation<ProzessVersionVeroeffentlichenMutation, ProzessVersionVeroeffentlichenMutationVariables>(ProzessVersionVeroeffentlichenDocument);
};