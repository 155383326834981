import * as Dialog from '@radix-ui/react-dialog';
import {Cross2Icon} from '@radix-ui/react-icons';
import {type FC, type ReactNode, useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';

export type ModalProps = {
  title: string;
  description?: string;
  children: ReactNode;
  className?: string;
  global?: boolean;
};

export const Modal: FC<ModalProps> = ({
  title,
  children,
  className,
  description,
  global = false,
}) => {
  const [mounted, setMounted] = useState(false);
  const mainContent: HTMLElement | undefined | null =
    global || !mounted ? undefined : document.getElementById('main-content');

  useEffect(() => {
    // If the Modal is rendered immediately (open = true), the `main-content` element may not exist yet.
    setMounted(true);
  }, []);

  return (
    mounted && (
      <Dialog.Portal container={mainContent}>
        <Dialog.Overlay
          className={twMerge(
            'icy-backdrop inset-0 z-10 flex items-center data-[state=open]:animate-overlayShow',
            global ? 'fixed' : 'sticky',
          )}
        >
          <Dialog.Content
            className={twMerge(
              'relative mx-auto max-h-fit min-w-24 max-w-5xl flex-1 overflow-hidden rounded-xl border-2 border-neutral-300 bg-white shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px]',
              className,
            )}
            {
              /* Suppress warning about missing description */
              ...(!description ? {'aria-describedby': undefined} : undefined)
            }
          >
            <Dialog.Title className='text-mauve12 m-0 rounded-t-xl border-b-[1px] border-neutral-300 bg-neutral-100 px-5 py-3 text-[17px] font-medium'>
              {title}
            </Dialog.Title>
            <div className='h-full max-h-[50vh] overflow-y-auto px-5 py-3'>
              {description && (
                <Dialog.Description className='text-mauve11 mb-5 mt-[10px] text-[15px] leading-normal'>
                  {description}
                </Dialog.Description>
              )}
              {children}
              <Dialog.Close asChild>
                <button
                  className='absolute right-[10px] top-[10px] inline-flex size-[25px] appearance-none items-center justify-center rounded-full focus:outline-none'
                  aria-label='Close'
                >
                  <Cross2Icon />
                </button>
              </Dialog.Close>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    )
  );
};
