import {type FC, useCallback, useEffect, useRef, useState} from 'react';
import {
  Background,
  Controls,
  MiniMap,
  ReactFlow,
  useEdgesState,
  useNodesState,
  type OnConnect,
  type OnConnectStart,
  type OnConnectEnd,
  type OnNodesDelete,
  type OnEdgesDelete,
  Position,
  type Edge,
  type Node,
  type NodeDragHandler,
  type XYPosition,
  type ReactFlowInstance,
  type OnNodesChange,
  type OnEdgesChange,
} from 'reactflow';

import 'reactflow/dist/style.css';
import './reactflow-style-overrides.css';
import {useConfirm} from 'src/components/modal/confirm-modal.tsx';
import {Layouter} from '../lib/layouter.tsx';
import {useProzessEditorAutoLayout, useProzessEditorStore} from '../lib/store';
import {useProzessEditorSelectionHandler} from '../lib/store/use-prozess-editor-selection-handler.ts';
import {type ProzessEditorSchritt} from '../prozess-version-erstellen.generated.ts';
import {KommunalappProzessEdge} from './kommunalapp-prozess-edge.tsx';
import {
  KommunalappSchrittNode,
  type KommunalappSchrittNodeData,
} from './kommunalapp-schritt-node.tsx';
import {
  NeuerSchrittSelect,
  newSchrittByType,
  type NeuerSchrittSelectProps,
} from './neuer-schritt.tsx';

const edgeTypes = {
  kommunalEdge: KommunalappProzessEdge,
};
const nodeTypes = {
  schritt: KommunalappSchrittNode,
};

export const EditorPane: FC = () => {
  const prozess = useProzessEditorStore((state) => state.prozess);
  useProzessEditorAutoLayout();
  const createSchritt = useProzessEditorStore((state) => state.createSchritt);
  const updateSchrittPosition = useProzessEditorStore(
    (state) => state.updateSchrittPosition,
  );
  const deleteSchritt = useProzessEditorStore((state) => state.deleteSchritt);
  const deleteVerbindung = useProzessEditorStore(
    (state) => state.deleteVerbindung,
  );
  const createVerbindung = useProzessEditorStore(
    (state) => state.createVerbindung,
  );

  const [nodes, setNodes, onNodesChange] =
    useNodesState<KommunalappSchrittNodeData>([]);

  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [neuerSchrittSelect, setNeuerSchrittSelect] = useState<
    | (Omit<NeuerSchrittSelectProps, 'onSelect' | 'onClickAway'> & {
        position: XYPosition;
      })
    | undefined
  >(undefined);

  const reactFlowRef = useRef<HTMLDivElement | null>(null);
  const reactFlowInstanceRef = useRef<ReactFlowInstance | null>(null);
  const connectingNodeId = useRef<string | null>(null);

  useEffect(() => {
    const nodeEdgeCounts = new Map<
      string,
      {incoming: number; outgoing: number}
    >();

    const edges: Edge<unknown>[] = [];

    for (const verbindung of prozess.verbindungen) {
      edges.push({
        id: verbindung.id,
        source: verbindung.vonId,
        target: verbindung.nachId,
        label: verbindung.name,
        animated: true,
        type: 'kommunalEdge',
        labelShowBg: true,
        labelBgBorderRadius: 6,
        labelBgPadding: [8, 4],
        labelBgStyle: {fill: '#FEE2AA'},
        labelStyle: {fontSize: 12},
        updatable: false,
      });

      const sourceNodeCounts = nodeEdgeCounts.get(verbindung.vonId) ?? {
        incoming: 0,
        outgoing: 0,
      };

      const targetNodeCounts = nodeEdgeCounts.get(verbindung.nachId) ?? {
        incoming: 0,
        outgoing: 0,
      };

      nodeEdgeCounts.set(verbindung.vonId, {
        ...sourceNodeCounts,
        outgoing: sourceNodeCounts.outgoing + 1,
      });
      nodeEdgeCounts.set(verbindung.nachId, {
        ...targetNodeCounts,
        incoming: sourceNodeCounts.incoming + 1,
      });
    }

    const nodes: Node<KommunalappSchrittNodeData>[] = [];

    for (const schritt of prozess.schritte) {
      const connections = nodeEdgeCounts.get(schritt.id) ?? {
        incoming: 0,
        outgoing: 0,
      };

      const isStart = schritt.id === prozess.startId;

      nodes.push({
        id: schritt.id,
        type: 'schritt',
        width: schritt.layout?.width,
        height: schritt.layout?.height,
        position: schritt.layout?.position ?? {x: 100, y: 100},
        data: {
          schritt: schritt,
          isStart,
          connections: connections,
        },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      });
    }

    setNodes(nodes);

    setEdges(edges);
  }, [
    setNodes,
    setEdges,
    prozess.verbindungen,
    prozess.startId,
    prozess.schritte,
  ]);

  const selectionHandler = useProzessEditorSelectionHandler();

  const onConnect = useCallback<OnConnect>(
    ({source, target}) => {
      connectingNodeId.current = null;

      if (!source || !target) return;

      const sourceSchritt = prozess.schritte.find((s) => s.id === source);
      const targetSchritt = prozess.schritte.find((s) => s.id === target);

      if (!source || !target) return;
      if (!sourceSchritt || !targetSchritt) return;

      createVerbindung({
        vonId: source,
        nachId: target,
        name: null,
        statusAenderung: null,
        statusAenderungBeschreibung: null,
        istRelevant: 'true',
      });
    },
    [prozess.schritte, createVerbindung],
  );

  const onConnectStart = useCallback<OnConnectStart>((_, {nodeId}) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback<OnConnectEnd>(
    (event) => {
      if (
        !connectingNodeId.current ||
        !reactFlowRef.current ||
        !('clientX' in event)
      )
        return;

      const eventTarget = event.target as HTMLElement | null;

      const targetIsPane = eventTarget?.classList.contains('react-flow__pane');
      const pane = reactFlowRef.current.getBoundingClientRect();

      const offsetY = 200;
      const offsetX = 250;

      if (targetIsPane) {
        setNeuerSchrittSelect({
          position: {
            x: event.clientX,
            y: event.clientY,
          },
          top:
            event.clientY < pane.bottom - offsetY
              ? event.clientY - pane.top
              : undefined,
          left:
            event.clientX < pane.right - offsetX
              ? event.clientX - pane.left
              : undefined,
          right:
            event.clientX >= pane.right - offsetX
              ? pane.width - event.clientX
              : undefined,
          bottom:
            event.clientY >= pane.bottom - offsetY
              ? pane.height - event.clientY + pane.top
              : undefined,
        });
      }
    },
    [setNeuerSchrittSelect],
  );

  const confirm = useConfirm();

  const onNodesDelete = useCallback<OnNodesDelete>(
    async (nodes) => {
      const confirmed = await confirm({
        content:
          'Sind Sie sicher, dass Sie diese Verbindung oder den Schritt löschen möchten?',
        confirmText: 'Löschen',
        confirmVariant: 'destructive',
      });

      if (!confirmed) return;

      for (const node of nodes) {
        const schritt = prozess.schritte.find((s) => s.id === node.id);
        if (!schritt) continue;
        deleteSchritt(schritt.id);
      }
    },
    [confirm, deleteSchritt, prozess.schritte],
  );

  const onEdgesDelete = useCallback<OnEdgesDelete>(
    async (edges) => {
      const confirmed = await confirm({
        content:
          'Sind Sie sicher, dass Sie diese Verbindung oder den Schritt löschen möchten?',
        confirmText: 'Löschen',
        confirmVariant: 'destructive',
      });

      if (!confirmed) return;

      for (const edge of edges) {
        deleteVerbindung(edge.id);
      }
    },
    [confirm, deleteVerbindung],
  );

  const addNewSchritt = async (
    schrittLabel: string,
    typeName: ProzessEditorSchritt['__typename'],
  ) => {
    const vonId = connectingNodeId.current!;
    const vonSchritt = prozess.schritte.find((s) => s.id === vonId);

    if (!vonSchritt) return;

    const schrittData = newSchrittByType({
      titel: schrittLabel,
      __typename: typeName,
    });

    const {width, height} = await Layouter.determineElementSize({
      ...schrittData,
      id: '',
    });

    const newSchritt = createSchritt(schrittData, {
      position: neuerSchrittSelect?.position
        ? reactFlowInstanceRef.current?.screenToFlowPosition(
            neuerSchrittSelect?.position,
          )
        : undefined,
      width,
      height,
    });

    createVerbindung({
      vonId,
      nachId: newSchritt.id,
      name: null,
      statusAenderung: null,
      statusAenderungBeschreibung: null,
      istRelevant: 'true',
    });
  };

  const onSchrittSelect: NeuerSchrittSelectProps['onSelect'] = (
    label,
    typename,
  ) => {
    addNewSchritt(label, typename);
    setNeuerSchrittSelect(undefined);
  };

  const onSchrittMoved = useCallback<NodeDragHandler>(
    (_, node) => {
      updateSchrittPosition(node.id, node.position);
    },
    [updateSchrittPosition],
  );

  const filteredOnNodesChange = useCallback<OnNodesChange>(
    (changes) =>
      onNodesChange(changes.filter((change) => change.type !== 'remove')),
    [onNodesChange],
  );

  const filteredOnEdgesChange = useCallback<OnEdgesChange>(
    (changes) =>
      onEdgesChange(changes.filter((change) => change.type !== 'remove')),
    [onEdgesChange],
  );

  if (!prozess) {
    return (
      <div className='flex flex-1 flex-col items-center justify-center'>
        Kein Prozess ausgewählt.
      </div>
    );
  }

  return (
    <div className='size-full bg-gray-100'>
      <ReactFlow
        onInit={(instance) => {
          reactFlowInstanceRef.current = instance;
        }}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={filteredOnNodesChange}
        onEdgesChange={filteredOnEdgesChange}
        onNodeDragStop={onSchrittMoved}
        onNodesDelete={onNodesDelete}
        onEdgesDelete={onEdgesDelete}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onPaneMouseEnter={() => setNeuerSchrittSelect(undefined)}
        edgesFocusable={false}
        multiSelectionKeyCode={null}
        selectionKeyCode={null}
        selectNodesOnDrag={false}
        nodeDragThreshold={1}
        snapGrid={[10, 10]}
        snapToGrid
        ref={reactFlowRef}
        onSelectionChange={({nodes, edges}) => selectionHandler(nodes, edges)}
      >
        <Background gap={10} />
        <Controls />
        <MiniMap pannable zoomable zoomStep={1} />
        {neuerSchrittSelect && (
          <NeuerSchrittSelect
            onSelect={onSchrittSelect}
            onClickAway={() => setNeuerSchrittSelect(undefined)}
            {...neuerSchrittSelect}
          />
        )}
      </ReactFlow>
    </div>
  );
};
