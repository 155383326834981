import {BackofficeNutzerArt} from '../__generated__/graphql.ts';

export const matchNutzerart = (
  ist: BackofficeNutzerArt,
  verlangt: BackofficeNutzerArt,
) => {
  if (ist === BackofficeNutzerArt.Superadmin) {
    return true;
  } else if (verlangt === BackofficeNutzerArt.Admin) {
    return ist === BackofficeNutzerArt.Admin;
  } else {
    return true;
  }
};
