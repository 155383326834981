import {useCallback} from 'react';
import {toast} from 'react-toastify';
import {Button} from '../../../../components/ui/button';
import {schrittZuInput, verbindungZuInput} from '../lib/input-converters.ts';
import {useProzessEditorStore} from '../lib/store';
import {dataToStoreProzess} from '../lib/store/prozess-aenderungen.ts';
import {useProzessVersionVeroeffentlichenMutation} from '../prozess-editor.generated.ts';
import {useNeueProzessVersionErstellenMutation} from '../prozess-version-erstellen.generated.ts';

export const Topbar = ({isReleased}: {isReleased?: boolean}) => {
  const {prozess, hasAenderungen, initializeFromProzess} =
    useProzessEditorStore((state) => ({
      prozess: state.prozess,
      hasAenderungen: state.hasAenderungen,
      initializeFromProzess: state.initializeFromProzess,
    }));

  const [, neueProzessVersionErstellenMutation] =
    useNeueProzessVersionErstellenMutation();

  const [, prozessVersionVeroeffentlichen] =
    useProzessVersionVeroeffentlichenMutation();

  const neueProzessVersionErstellen = useCallback(
    async (veroeffentlichen: boolean) => {
      const schrittInputs = prozess.schritte.map(schrittZuInput);
      const verbindungInputs = prozess.verbindungen.map(verbindungZuInput);

      const response = await neueProzessVersionErstellenMutation({
        input: {
          prozessId: prozess.id,
          verbindungen: verbindungInputs,
          schritte: schrittInputs,
          veroeffentlichen: veroeffentlichen,
        },
      });

      const newProzess = response.data?.prozessVersionErstellen.prozess;
      if (newProzess) {
        initializeFromProzess(
          dataToStoreProzess(newProzess),
          prozess.layoutVersion + 1,
        );
        toast.success(
          veroeffentlichen
            ? 'Neue Version veröffentlicht'
            : 'Version gespeichert',
        );
      } else {
        toast.error(
          veroeffentlichen
            ? 'Neue Version konnte nicht veröffentlicht werden'
            : 'Version konnte nicht gespeichert werden',
        );
      }
    },
    [prozess, initializeFromProzess, neueProzessVersionErstellenMutation],
  );
  return (
    <div className='flex h-[72px] items-center justify-end gap-4 border-b p-2 px-4'>
      <h3 className='mr-auto'>{prozess.name}</h3>
      {hasAenderungen && (
        <Button
          variant='solid'
          type='button'
          disabled={!prozess.isComplete}
          onClick={() => neueProzessVersionErstellen(false)}
        >
          Änderungen speichern
        </Button>
      )}
      {(hasAenderungen || !isReleased) && (
        <Button
          variant='text'
          type='button'
          disabled={!prozess.isComplete}
          onClick={async () => {
            if (!hasAenderungen && prozess.latestVersionId) {
              const result = await prozessVersionVeroeffentlichen({
                input: {prozessVersionId: prozess.latestVersionId},
              });
              if (result.data?.prozessVersionVeroeffentlichen) {
                toast.success('Neue Version veröffentlicht');
              } else {
                toast.error('Neue Version konnte nicht veröffentlicht werden');
              }
            } else {
              neueProzessVersionErstellen(true);
            }
          }}
        >
          Veröffentlichen
        </Button>
      )}
    </div>
  );
};
