import {useFormBuilder} from '@atmina/formbuilder';
import {type FC, useCallback, useEffect} from 'react';
import {MdOutlineBackspace} from 'react-icons/md';
import {useEdges, useStore} from 'reactflow';
import {useConfirm} from 'src/components/modal/confirm-modal.tsx';
import {ExpressionInputFormField} from '../../../../components/form/fields/expression-input-field/expression-input-field.tsx';
import {InputFormField} from '../../../../components/form/fields/input-field';
import {Form} from '../../../../components/form/form.tsx';
import {Sidebar} from '../../../../components/sidebar/sidebar.tsx';
import {Button} from '../../../../components/ui/button';
import {useProzessEditorStore} from '../lib/store';
import {type VerbindungDaten} from '../lib/store/prozess-aenderungen.ts';
import {SidebarHeader} from './sidebar-header';

type VerbindungForm = VerbindungDaten;

export const VerbindungSidebar: FC = () => {
  const selectedVerbindung = useProzessEditorStore(
    (store) => store.selectedVerbindung,
  );
  const updateVerbindung = useProzessEditorStore(
    (store) => store.updateVerbindung,
  );
  const deleteVerbindung = useProzessEditorStore(
    (store) => store.deleteVerbindung,
  );

  const builder = useFormBuilder<VerbindungForm>();

  const {reset} = builder;
  useEffect(() => {
    if (!selectedVerbindung) {
      reset();
      return;
    }

    reset(selectedVerbindung);
  }, [reset, selectedVerbindung]);

  const edges = useEdges();
  const setEdges = useStore((store) => store.setEdges);

  const handleSubmit = useCallback(
    (form: VerbindungForm) => {
      const id = selectedVerbindung?.id;
      if (!id) return;
      updateVerbindung(id, form);

      const target = edges.find((e) => e.id === id);
      if (!target || target.label === form.name) return;

      target.label = form.name;

      setEdges(edges);
    },
    [selectedVerbindung, updateVerbindung, setEdges, edges],
  );

  const confirm = useConfirm();

  const onDeleteVerbindung = useCallback(async () => {
    if (!selectedVerbindung) return;

    const confirmed = await confirm({
      content: 'Sind Sie sicher, dass Sie diese Verbindung löschen möchten?',
      confirmText: 'Löschen',
      confirmVariant: 'destructive',
    });

    if (!confirmed) return;

    deleteVerbindung(selectedVerbindung.id);
  }, [confirm, deleteVerbindung, selectedVerbindung]);

  return (
    <Sidebar isOpen={!!selectedVerbindung}>
      <Form
        builder={builder}
        onSubmit={handleSubmit}
        className='flex grow flex-col overflow-hidden'
      >
        <SidebarHeader on={builder.fields.name} placeholder='Verbindung' />
        <div className='flex h-full w-[480px] flex-1 flex-col gap-4 overflow-auto p-4'>
          <InputFormField label='Name' on={builder.fields.name} />
          <InputFormField
            label='Statusänderung (Titel)'
            on={builder.fields.statusAenderung}
          />
          <InputFormField
            label='Statusänderung (Beschreibung)'
            on={builder.fields.statusAenderungBeschreibung}
          />
          <ExpressionInputFormField
            label='Bedingung'
            on={builder.fields.istRelevant}
            kind='bool'
          />

          <Button variant='solid'>Übernehmen</Button>

          <Button
            type='button'
            variant='destructive'
            onClick={onDeleteVerbindung}
          >
            <div className='flex flex-col'>
              <div>Verbindung löschen</div>
              <div className='text-xs text-red-200'>
                oder mit <MdOutlineBackspace className='mb-px inline-block' />{' '}
                Backspace
              </div>
            </div>
          </Button>
        </div>
      </Form>
    </Sidebar>
  );
};
