import {type RouteObject} from 'react-router-dom';
import {GlobaleFunktionsberechtigung} from '../../__generated__/graphql.ts';
import {IfFunktionsberechtigung} from '../../components/if-funktionsberechtigung.tsx';
import {Prozesse} from './prozesse/prozesse.tsx';

export const prozesseVerwaltenRoute: RouteObject = {
  path: 'prozessverwaltung',
  element: (
    <IfFunktionsberechtigung
      funktionsberechtigung={GlobaleFunktionsberechtigung.Prozessverwaltung}
      modal
    >
      <Prozesse />
    </IfFunktionsberechtigung>
  ),
};
