import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';
import {mz} from 'mehrzahl';
import {useMemo, type FC} from 'react';
import {FaExclamationTriangle} from 'react-icons/fa';
import {Handle, type NodeProps, Position} from 'reactflow';
import {twMerge} from 'tailwind-merge';
import {type SchrittDaten} from '../lib/store/prozess-aenderungen.ts';
import {type ProzessEditorSchritt} from '../prozess-version-erstellen.generated.ts';

export type NodeConnectionCounts = {
  incoming: number;
  outgoing: number;
};

export type KommunalappSchrittNodeData = {
  schritt: SchrittDaten;
  connections: NodeConnectionCounts;
  isStart: boolean;
};

export type KommunalappSchrittNodeProps = NodeProps<KommunalappSchrittNodeData>;

export const KommunalappSchrittNode: FC<KommunalappSchrittNodeProps> = ({
  data: {schritt, connections, isStart},
  selected,
}) => (
  <>
    {!isStart && <Handle type='target' position={Position.Left} />}
    <KommunalappSchrittNodeRender
      schritt={schritt}
      connections={connections}
      selected={selected}
      isStart={isStart}
    />
    {schritt.__typename !== 'EndeSchritt' && (
      <Handle type='source' position={Position.Right} />
    )}
  </>
);

export type KommunalappSchrittNodeRenderProps = {
  schritt: SchrittDaten;
  connections: NodeConnectionCounts;
  isStart: boolean;
  selected?: boolean;
};

const schrittArtLabels: Record<ProzessEditorSchritt['__typename'], string> = {
  WarteAufBezahlungAbgeschlossenSchritt: 'Bezahlung',
  BezahlenSchritt: 'Bezahlung',
  EndeSchritt: 'Ende',
  FormularSchritt: 'Formular',
  ProgrammatischerSchritt: 'Automatisierung',
  EmailForwarderExportSchritt: 'EMail',
  GovManagerRestExportSchritt: 'GovManager Rest Export',
  WarteAufGovManagerSchritt: 'GovManager Rest Export',
};

export const KommunalappSchrittNodeRender: FC<
  KommunalappSchrittNodeRenderProps
> = ({schritt, connections, isStart, selected = false}) => {
  const errors = useMemo(() => {
    const messages: string[] = [];

    if (connections.incoming == 0 && !isStart) {
      messages.push('Schritt benötigt mindestens eine eingehende Verbindung');
    }

    if (connections.outgoing == 0 && schritt.__typename !== 'EndeSchritt') {
      messages.push('Schritt benötigt mindestens eine ausgehende Verbindung');
    }

    return messages;
  }, [connections.incoming, connections.outgoing, isStart, schritt.__typename]);
  return (
    <div
      className={twMerge(
        'relative min-w-32 rounded border-[1.5px] border-primary bg-white',
        selected && 'bg-primary',
      )}
    >
      <div className='flex h-0 -translate-y-1/2 items-center justify-center gap-2'>
        <div className='rounded-full border bg-white px-2 text-center text-xs'>
          {schrittArtLabels[schritt.__typename]}
        </div>

        {errors.length > 0 && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className='grid place-items-center rounded-full bg-red-500 px-2 text-xs text-white'>
                {mz(errors.length)`$value Problem{|e}`}
              </TooltipTrigger>
              <TooltipContent
                side='top'
                sideOffset={10}
                className='z-10 flex flex-col gap-1 p-2'
              >
                {errors?.map((message) => (
                  <div
                    key={message}
                    className='flex items-center gap-1 rounded-md bg-red-100 px-2 text-base text-red-600'
                  >
                    <FaExclamationTriangle size={10} />
                    {message}
                  </div>
                ))}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      <div className={twMerge('p-4', selected && 'text-white')}>
        <p className='text-center'>{schritt.titel}</p>

        {schritt.__typename === 'FormularSchritt' &&
          schritt.felder.length > 0 && (
            <div className='mt-2 grid grid-cols-2 gap-x-3 border-t pt-2'>
              <span className='col-span-2'>
                {mz(schritt.felder.length)`Formularfeld{|er}`}:
              </span>
              {schritt.felder.map((f) => (
                <p
                  key={f.id}
                  className={
                    (f.angezeigterName?.length ?? 0) > 30 ? 'col-span-2' : ''
                  }
                >
                  {f.angezeigterName ?? 'Eingabe'}
                </p>
              ))}
            </div>
          )}
      </div>
    </div>
  );
};
