export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export enum AssignedAusfuehrungenFilter {
  Abgeschlossen = 'ABGESCHLOSSEN',
  InBearbeitung = 'IN_BEARBEITUNG',
  Neu = 'NEU',
}

export enum AusfuehrungStatus {
  Abgeschlossen = 'ABGESCHLOSSEN',
  BearbeitungDurchBuerger = 'BEARBEITUNG_DURCH_BUERGER',
  BearbeitungDurchKommune = 'BEARBEITUNG_DURCH_KOMMUNE',
}

export enum BackofficeNutzerArt {
  Admin = 'ADMIN',
  Nutzer = 'NUTZER',
  Superadmin = 'SUPERADMIN',
}

export enum BackofficeNutzerStatus {
  InvitationExpired = 'INVITATION_EXPIRED',
  Invited = 'INVITED',
  Registered = 'REGISTERED',
}

export enum FallbackLocaleInputType {
  De = 'de',
  En = 'en',
  None = 'none',
}

export enum FunktionsberechtigungInOrganisationseinheit {
  Backoffice = 'BACKOFFICE',
  BackofficeAntraege = 'BACKOFFICE_ANTRAEGE',
  BackofficeNutzendenverwaltung = 'BACKOFFICE_NUTZENDENVERWALTUNG',
  Inhaltspflege = 'INHALTSPFLEGE',
  InhaltspflegeGeoflaechen = 'INHALTSPFLEGE_GEOFLAECHEN',
  InhaltspflegeIntegrationen = 'INHALTSPFLEGE_INTEGRATIONEN',
  InhaltspflegeNewsArtikel = 'INHALTSPFLEGE_NEWS_ARTIKEL',
  InhaltspflegePois = 'INHALTSPFLEGE_POIS',
  InhaltspflegeSensoren = 'INHALTSPFLEGE_SENSOREN',
  InhaltspflegeVeranstaltungen = 'INHALTSPFLEGE_VERANSTALTUNGEN',
  InhaltspflegeWettermesspunkte = 'INHALTSPFLEGE_WETTERMESSPUNKTE',
}

export enum GlobaleFunktionsberechtigung {
  Prozessverwaltung = 'PROZESSVERWALTUNG',
}

export enum HistoryMode {
  LastDay = 'LAST_DAY',
  LastWeek = 'LAST_WEEK',
}

export enum Integration_Integrationtype {
  Ikiss = 'IKISS',
  Nolis = 'NOLIS',
  Rss = 'RSS',
}

export enum KommuneDesignFonts_Body {
  CenturyGothic = 'CenturyGothic',
  Mulish = 'Mulish',
  NotoSans = 'NotoSans',
  RobotoSlab = 'RobotoSlab',
}

export enum KommuneDesignFonts_Headings {
  CenturyGothic = 'CenturyGothic',
  Mulish = 'Mulish',
  NotoSans = 'NotoSans',
  RobotoSlab = 'RobotoSlab',
}

export enum LocaleInputType {
  De = 'de',
  En = 'en',
}

export enum LoginResult {
  NeedsConfirmation = 'NEEDS_CONFIRMATION',
  Success = 'SUCCESS',
  WrongLogin = 'WRONG_LOGIN',
}

export enum NewsArtikel__Status {
  Draft = 'draft',
  Published = 'published',
}

export enum NewsArtikel__Status_Input {
  Draft = 'draft',
  Published = 'published',
}

export enum PasswordConfirmationResult {
  CodeExpired = 'CODE_EXPIRED',
  InvalidCode = 'INVALID_CODE',
  Success = 'SUCCESS',
}

export enum PendingConfirmationReason {
  Invited = 'INVITED',
  PasswordReset = 'PASSWORD_RESET',
}

export enum Poi_AdditionalInformation_Kind {
  Email = 'email',
  Other = 'other',
  Phone = 'phone',
  Website = 'website',
}

export enum Poi_AdditionalInformation__Kind_Input {
  Email = 'email',
  Other = 'other',
  Phone = 'phone',
  Website = 'website',
}

export enum RegisterBackofficeUserResult {
  CodeExpired = 'CODE_EXPIRED',
  InvalidCode = 'INVALID_CODE',
  Success = 'SUCCESS',
}

export enum SlotValueType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Location = 'LOCATION',
  ReadOnly = 'READ_ONLY',
  Signature = 'SIGNATURE',
  String = 'STRING',
  Time = 'TIME',
  Upload = 'UPLOAD',
}

export enum StringValidationType {
  Default = 'DEFAULT',
  Iban = 'IBAN',
}

export enum WeiterleitungArt {
  Kopieren = 'KOPIEREN',
  Schliessen = 'SCHLIESSEN',
}

export enum WeiterleitungEmpfaenger {
  Extern = 'EXTERN',
  Intern = 'INTERN',
}
