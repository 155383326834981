import type {RouteObject} from 'react-router-dom';
import {FunktionsberechtigungInOrganisationseinheit} from '../../__generated__/graphql.ts';
import {IfFunktionsberechtigung} from '../../components/if-funktionsberechtigung.tsx';
import {Nutzende} from './nutzende/nutzende.tsx';

export const nutzendenverwaltungRoute: RouteObject = {
  path: 'nutzendenverwaltung',
  children: [
    {
      index: true,
      element: (
        <IfFunktionsberechtigung
          funktionsberechtigung={
            FunktionsberechtigungInOrganisationseinheit.BackofficeNutzendenverwaltung
          }
          modal
        >
          <Nutzende />
        </IfFunktionsberechtigung>
      ),
    },
  ],
};
