import {useEffect, type FC} from 'react';
import {useParams} from 'react-router';
import {ReactFlowProvider} from 'reactflow';
import {EditorPane} from './components/editor-pane.tsx';
import {ProzessSidebar} from './components/prozess-sidebar.tsx';
import {Topbar} from './components/topbar.tsx';
import {VerbindungSidebar} from './components/verbindung-sidebar.tsx';
import {useProzessEditorStore} from './lib/store/index.ts';
import {dataToStoreProzess} from './lib/store/prozess-aenderungen.ts';
import {useGetProzessByIdQuery} from './prozess-editor.generated.ts';

export const ProzessEditor: FC = () => {
  const initializeFromProzess = useProzessEditorStore(
    (state) => state.initializeFromProzess,
  );

  const {id} = useParams();

  const [{data}] = useGetProzessByIdQuery({
    variables: {prozessId: id ?? ''},
    pause: !id,
  });
  const prozess =
    data?.my.__typename == 'BackofficeUser'
      ? data.my.kommune.prozessById
      : null;
  useEffect(() => () => initializeFromProzess(null), [initializeFromProzess]);
  useEffect(() => {
    if (prozess != null) {
      const prev = useProzessEditorStore.getState().prozess.layoutVersion;
      initializeFromProzess(dataToStoreProzess(prozess), prev + 1);
    }
  }, [prozess, initializeFromProzess]);

  const selectedSchritt = useProzessEditorStore(
    (state) => state.selectedSchritt,
  );
  return (
    <ReactFlowProvider>
      <div className='flex h-full overflow-hidden'>
        <div className='flex flex-1 flex-col'>
          <Topbar isReleased={!!prozess?.latestVersion?.publishedAt} />

          <div className='flex flex-1'>
            <EditorPane />
          </div>
        </div>
        {/*Workaround fix to prevent inconsistent state*/}
        <ProzessSidebar key={selectedSchritt?.id} />
        <VerbindungSidebar />
      </div>

      <div
        id='kommunalapp-grid-measurement-container'
        className='invisible absolute -z-10'
      />
    </ReactFlowProvider>
  );
};
