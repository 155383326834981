import * as Types from '../../../__generated__/graphql';

import {
  BoolExpression,
  IntExpression,
  StringExpression,
} from '@expressions/expressions.ts';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type LoggedInLayoutQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LoggedInLayoutQuery = { __typename?: 'Query', my: { __typename?: 'BackofficeUser', assignedAusfuehrungenCount: { __typename?: 'AssignedAusfuehrungenCounts', neu: number, inBearbeitung: number, abgeschlossen: number }, kommune: { __typename?: 'Kommune', id: string, rechtsTexte: { __typename?: 'BereichBKommune_Legal', impressum?: string | null, privacy?: string | null } } } | { __typename?: 'Buerger' } | { __typename?: 'SystemUser' } };



export const LoggedInLayoutDocument = gql`
    query loggedInLayout {
  my {
    ... on BackofficeUser {
      assignedAusfuehrungenCount {
        neu
        inBearbeitung
        abgeschlossen
      }
      kommune {
        id
        rechtsTexte {
          impressum
          privacy
        }
      }
    }
  }
}
    `;

export function useLoggedInLayoutQuery(options?: Omit<Urql.UseQueryArgs<LoggedInLayoutQueryVariables>, 'query'>) {
  return Urql.useQuery<LoggedInLayoutQuery, LoggedInLayoutQueryVariables>({ query: LoggedInLayoutDocument, ...options });
};