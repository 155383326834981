import * as Dialog from '@radix-ui/react-dialog';
import {
  type FC,
  createContext,
  useState,
  type ReactNode,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import {Button, type ButtonProps} from '../ui/button';
import {Modal} from './modal';

export type ConfirmationDialogProps = {
  title?: string;
  content?: string | JSX.Element;
  confirmText?: string;
  confirmVariant?: ButtonProps['variant'];
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmationDialog = ({
  title,
  content,
  confirmText,
  confirmVariant,
  onConfirm,
  onCancel,
}: ConfirmationDialogProps) => (
  <Dialog.Root
    open
    onOpenChange={(open) => {
      if (!open) {
        onCancel();
      }
    }}
  >
    <Modal title={title ?? 'Bestätigen'} className='max-w-2xl'>
      <div className='flex h-fit w-full flex-col gap-4 p-4 sm:min-w-96'>
        {typeof content === 'string' && <p>{content}</p>}

        <div className='ml-auto flex gap-4'>
          <Button variant='text' onClick={onCancel}>
            Abbrechen
          </Button>
          <Button variant={confirmVariant ?? 'solid'} onClick={onConfirm}>
            {confirmText ?? 'Bestätigen'}
          </Button>
        </div>
      </div>
    </Modal>
  </Dialog.Root>
);

type ConfirmData = Omit<ConfirmationDialogProps, 'onConfirm' | 'onCancel'>;

export type ConfirmContextValue = {
  confirm: (data: ConfirmData) => Promise<boolean>;
};

export const ConfirmContext = createContext<ConfirmContextValue | null>(null);

export const useConfirm = () => {
  const value = useContext(ConfirmContext);

  if (!value)
    throw new Error(
      'useConfirm must be used within a ConfirmationContextProvider',
    );

  return value.confirm;
};

interface ConfirmContextProviderProps {
  children: ReactNode;
}

export const ConfirmContextProvider: FC<ConfirmContextProviderProps> = ({
  children,
}) => {
  const confirmationPromiseRef = useRef<
    [promise: Promise<boolean>, resolve: (confirmed: boolean) => void] | null
  >(null);
  const [confirmData, setConfirmData] = useState<ConfirmData | null>(null);

  const confirm = useCallback((props: ConfirmData) => {
    if (confirmationPromiseRef.current) {
      return confirmationPromiseRef.current[0];
    }

    let resolve: (confirmed: boolean) => void | undefined;
    const promise = new Promise<boolean>((r) => {
      resolve = (c) => {
        r(c);
        confirmationPromiseRef.current = null;
      };
    });

    setConfirmData(props);

    confirmationPromiseRef.current = [promise, resolve!];

    return promise;
  }, []);

  const onDecide = useCallback((confirmed: boolean) => {
    setConfirmData(null);
    confirmationPromiseRef.current?.[1]?.(confirmed);
  }, []);

  return (
    <ConfirmContext.Provider value={useMemo(() => ({confirm}), [confirm])}>
      {children}
      {confirmData && (
        <ConfirmationDialog
          {...confirmData}
          onConfirm={() => onDecide(true)}
          onCancel={() => onDecide(false)}
        />
      )}
    </ConfirmContext.Provider>
  );
};
