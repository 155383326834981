import {type FC, Fragment, type PropsWithChildren} from 'react';
import {MdLogout} from 'react-icons/md';
import {Link, useLocation} from 'react-router-dom';
import {twMerge} from 'tailwind-merge';
import {useAuth} from '../../../../../lib/context/auth/auth';
import {IfFunktionsberechtigung} from '../../../../if-funktionsberechtigung.tsx';
import {Link as NavLink} from '../../../../link/link';
import {mainMenuItems, UserMenu} from '../../../../user-menu/menu-items';
import {type NavigationProps} from '../desktop-navigation/desktop-navigation';

export const MobileNavigation: FC<NavigationProps> = ({
  ausfuehrungenNeuCount,
  ausfuehrungenInBearbeitungCount,
  ausfuehrungenAbgeschlossenCount,
}) => {
  const {logout} = useAuth();

  return (
    <div className='relative size-full overflow-y-scroll bg-white text-lg'>
      <div className='flex flex-col items-start'>
        <UserMenu className='px-6 py-4' />
        <hr className='w-full' />
        <MobileNavItem to='/antraege/neu' count={ausfuehrungenNeuCount}>
          Neue Anträge
        </MobileNavItem>
        <hr className='w-full' />
        <MobileNavItem
          to='/antraege/in-bearbeitung'
          count={ausfuehrungenInBearbeitungCount}
        >
          Anträge in Bearbeitung
        </MobileNavItem>
        <hr className='w-full' />
        <MobileNavItem
          to='/antraege/abgeschlossen'
          count={ausfuehrungenAbgeschlossenCount}
        >
          Abgeschlossene Anträge
        </MobileNavItem>
        <hr className='w-full' />
        {mainMenuItems.map(({label, href, external, funktionsberechtigung}) => (
          <Fragment key={label}>
            <IfFunktionsberechtigung
              funktionsberechtigung={funktionsberechtigung}
            >
              <MobileNavItem to={href} external={external}>
                {label}
              </MobileNavItem>
              <hr className='w-full' />
            </IfFunktionsberechtigung>
          </Fragment>
        ))}
        <MobileNavItem
          onClick={logout}
          icon={<MdLogout className='ml-2 inline-block size-6' />}
        >
          Abmelden
        </MobileNavItem>
      </div>
    </div>
  );
};

type CommonNavItemProps = {
  count?: number;
  icon?: React.ReactNode;
  external?: boolean;
};

type NavItemLinkProps = {
  to: string;
} & CommonNavItemProps;

type NavItemButtonProps = {
  onClick: () => void;
} & CommonNavItemProps;

export const MobileNavItem: FC<
  PropsWithChildren<NavItemLinkProps | NavItemButtonProps>
> = (props) => {
  const {pathname} = useLocation();
  const isActive =
    !props.external &&
    'to' in props &&
    !props.external &&
    props.to === pathname;

  const {count, children, icon} = props;

  const NavItemContent = (
    <div className='flex items-center'>
      {isActive && <div className='-ml-4 mr-3 h-6 w-1 rounded-xl bg-primary' />}
      {children}
      {!!count && (
        <div
          className={twMerge(
            'ml-2 inline-flex size-6 items-center justify-center rounded-full text-xs',
            isActive
              ? 'bg-primary-light text-primary'
              : 'bg-gray-100 text-gray-400',
          )}
        >
          {count}
        </div>
      )}
      {icon}
    </div>
  );

  return 'onClick' in props ? (
    <button className='px-6 py-4 font-bold' onClick={props.onClick}>
      {NavItemContent}
    </button>
  ) : props.external ? (
    <Link
      to={props.to}
      reloadDocument={props.external}
      className='h-auto px-6 py-4 font-bold'
    >
      {NavItemContent}
    </Link>
  ) : (
    <NavLink to={props.to} className='h-auto px-6 py-4'>
      {NavItemContent}
    </NavLink>
  );
};
