import {type FC} from 'react';
import {FaFilePdf} from 'react-icons/fa';
import {twMerge} from 'tailwind-merge';
import {SlotValue} from '../../../../../components/ui/slot-value';
import {
  type SlotFragment,
  type AntragDetailAusfuehrung,
} from '../../detail.generated.ts';
import {FreigabeStatus} from '../freigabe-status.tsx';

const guessIsPdf = (data: string) => data.startsWith('JVBERi0');

export type UploadFormularFeldProps = {
  slotName: string;
  anweisung?: string;
  ausfuehrung: AntragDetailAusfuehrung;
  slots: SlotFragment[];
};

export const UploadFormularFeld: FC<UploadFormularFeldProps> = ({
  ausfuehrung,
  slotName,
  anweisung,
  slots,
}) => {
  const slotValue = ausfuehrung.slotValues.find(
    (sv) => sv.slotName === slotName,
  );

  return (
    <div
      className={twMerge(
        'flex h-fit w-full flex-col gap-4 rounded-lg border border-gray-300 bg-gray-50 p-4',
      )}
    >
      <div className='flex grow gap-4 '>
        <SlotValue<string> value={slotValue ?? ''}>
          {(value) =>
            guessIsPdf(value) ? (
              <a
                download='document.pdf'
                href={'data:application/pdf;base64,' + value}
                className='flex min-h-20 w-full items-center justify-center border border-black hover:cursor-pointer'
              >
                <FaFilePdf className='size-12 text-red-600' />
              </a>
            ) : (
              <div className='flex h-52 w-max border border-black'>
                <img alt='' src={'data:image/jpg;base64,' + value} />
              </div>
            )
          }
        </SlotValue>
      </div>
      <FreigabeStatus
        ausfuehrungId={ausfuehrung.id}
        slotValue={slotValue}
        slots={slots}
      />
      {anweisung && <p className='min-h-[4rem]'>{anweisung}</p>}
    </div>
  );
};
