import {
  getSmartEdge,
  pathfindingJumpPointNoDiagonal,
  type SmartEdgeOptions,
  SmartStepEdge,
  svgDrawStraightLinePath,
} from '@tisoap/react-flow-smart-edge';
import {type CSSProperties, type FC} from 'react';
import {EdgeLabelRenderer, type EdgeProps, StepEdge, useNodes} from 'reactflow';
import {useProzessEditorStore} from '../lib/store';

export type KommunalappProzessEdgeProps = EdgeProps<unknown>;

const selectedEdgeStyle: CSSProperties = {
  stroke: 'rgb(var(--color-primary))',
  strokeWidth: 2,
};

// Copied from SmartStepEdge
const stepConfiguration: SmartEdgeOptions = {
  drawEdge: svgDrawStraightLinePath,
  generatePath: pathfindingJumpPointNoDiagonal,
  fallback: StepEdge,
};

export const KommunalappProzessEdge: FC<KommunalappProzessEdgeProps> = (
  props,
) => {
  const selectedSchritt = useProzessEditorStore(
    (state) => state.selectedSchritt,
  );

  const labelExists = props.label != null;
  const {style, source, target} = props;
  const isRelatedToSelectedNode =
    source === selectedSchritt?.id || target === selectedSchritt?.id;

  const nodes = useNodes();

  const edge = getSmartEdge({
    ...props,
    nodes,
    options: stepConfiguration,
  });

  const appliedStyle = isRelatedToSelectedNode
    ? {...style, ...selectedEdgeStyle}
    : style;

  return (
    <>
      <SmartStepEdge {...props} style={appliedStyle} labelBgPadding={[10, 5]} />
      {labelExists && (
        <EdgeLabelRenderer>
          <div
            {...props}
            className='edge-label-renderer__custom-edge nodrag nopan heute absolute -top-4 rounded-full border bg-white px-2 text-center text-xs'
            style={{
              transform: `translate(-50%, -50%) translate(${edge?.edgeCenterX}px,${edge?.edgeCenterY}px)`,
            }}
          >
            Button
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};
