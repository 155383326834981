import {useFormBuilder} from '@atmina/formbuilder';
import * as Dialog from '@radix-ui/react-dialog';
import {type FC} from 'react';
import {type SubmitHandler} from 'react-hook-form';
import SVG from 'react-inlinesvg';
import {toast} from 'react-toastify';
import {EmailFormField} from 'src/components/form/fields/email-field/email-field';
import {RadioFormField} from 'src/components/form/fields/radio-field/radio-field';
import {SelectFormField} from 'src/components/form/fields/select-field';
import {Form} from 'src/components/form/form';
import {Modal} from 'src/components/modal/modal';
import {Button} from 'src/components/ui/button';
import {useAuth} from 'src/lib/context/auth/auth';
import {
  WeiterleitungArt,
  WeiterleitungEmpfaenger,
} from '../../../../__generated__/graphql';
import ConvertToText from '../../../../assets/convert_to_text.svg';
import {
  useAusfuehrungWeiterleitenMutation,
  useMitarbeitendeQuery,
} from './ausfuehrung-weiterleiten.generated';

type AusfuehrungWeiterleitenProps = {
  ausfuehrungId: string;
  currentSchrittAbteilungId: string | null;
  onOpenChange: (open: boolean) => void;
};

type AntragWeiterleitenFormData = {
  ausfuehrungId: string;
  weiterleitungEmpfaenger: WeiterleitungEmpfaenger;
  empfaengerId?: string;
  empfaengerEmail?: string;
  weiterleitungArt?: WeiterleitungArt;
};

export const AusfuehrungWeiterleiten: FC<AusfuehrungWeiterleitenProps> = ({
  ausfuehrungId,
  currentSchrittAbteilungId,
  onOpenChange,
}) => {
  const {user} = useAuth();
  const builder = useFormBuilder<AntragWeiterleitenFormData>({
    defaultValues: {
      ausfuehrungId: ausfuehrungId,
      weiterleitungEmpfaenger: WeiterleitungEmpfaenger.Intern,
      empfaengerId: undefined,
      empfaengerEmail: '',
      weiterleitungArt: WeiterleitungArt.Kopieren,
    },
  });
  const empfaengerArt = builder.fields.weiterleitungEmpfaenger.$useWatch();

  const [{data}] = useMitarbeitendeQuery({
    variables: {
      groupId: currentSchrittAbteilungId,
    },
  });

  const [, antragWeiterleiten] = useAusfuehrungWeiterleitenMutation();

  const mitarbeitende =
    data?.my.__typename === 'BackofficeUser'
      ? data.my.kommune.mitarbeiter
      : null;

  const filteredMitarbeitende = mitarbeitende?.filter((m) => m.id != user?.id);

  const buttonDisabled =
    empfaengerArt == WeiterleitungEmpfaenger.Intern &&
    mitarbeitende?.length == 0;

  const handleSubmit: SubmitHandler<AntragWeiterleitenFormData> = async (
    data,
  ) => {
    const {data: d} = await antragWeiterleiten({
      input: data,
    });
    const errors = d?.ausfuehrungWeiterleiten.errors;
    if (!errors) {
      toast.success('Antrag erfolgreich weitergeleitet');
      onOpenChange(false);
    }
  };

  return (
    <Modal title='Antrag weiterleiten' className='min-h-[60vh] max-w-3xl'>
      <Form builder={builder} onSubmit={handleSubmit}>
        <div className='flex flex-col gap-6'>
          <p>
            Sie können diesen Antrag innerhalb der Organisation z.B. an
            bestimmte Sachbearbeiter oder Abteilungen weiterleiten oder per
            E-Mail an externe Personen.
          </p>
          <RadioFormField
            on={builder.fields.weiterleitungEmpfaenger}
            orientation='horizontal'
            items={[
              {
                name: 'Innerhalb der Abteilung (Intern)',
                value: WeiterleitungEmpfaenger.Intern,
              },
              {
                name: 'An E-Mail Adresse weiterleiten (Extern)',
                value: WeiterleitungEmpfaenger.Extern,
              },
            ]}
          />
          {empfaengerArt == WeiterleitungEmpfaenger.Intern &&
            (filteredMitarbeitende?.length != 0 ? (
              <SelectFormField
                label='Sachbearbeiter auswählen'
                rules={{
                  validate: (value) => {
                    if (!value) {
                      return 'Bitte wählen sie einen Mitarbeiter aus.';
                    }
                  },
                }}
                on={builder.fields.empfaengerId}
                items={
                  filteredMitarbeitende
                    ? filteredMitarbeitende?.map((m) => ({
                        name: `${m.vorname} ${m.nachname}`,
                        value: m.id,
                        customItem: (
                          <div className='flex flex-row items-center gap-2'>
                            <div className='grid aspect-square size-9 h-full place-items-center rounded-full border-2 border-gray-500 bg-orange-200 p-1 text-lg leading-4 text-gray-700'>
                              {(
                                m.vorname.charAt(0) + m.nachname.charAt(0)
                              ).toLocaleUpperCase()}
                            </div>
                            <div className='flex flex-col'>
                              <div>
                                {m.vorname} {m.nachname}
                              </div>
                              <div className='text-xs text-gray-500'>
                                {m.eMail}
                              </div>
                            </div>
                          </div>
                        ),
                      }))
                    : []
                }
              />
            ) : (
              <div className='rounded-md border border-yellow-500 bg-yellow-50 px-3 py-2'>
                Der Antrag kann intern nicht weitergeleitet werden, da es keine
                weiteren Mitarbeiter in ihrer Abteilung gibt, bzw. in der
                Abteilung, die für diesen Antrag zugeordnet ist.
              </div>
            ))}
          {empfaengerArt == WeiterleitungEmpfaenger.Extern && (
            <>
              <EmailFormField
                label='E-Mail Adresse eingeben'
                on={builder.fields.empfaengerEmail}
                rules={{required: true}}
              />
              <p>
                Der Antrag wird dem Empfänger als PDF-Datei zugeschickt. Bitte
                wählen Sie aus, wie der Antrag behandelt werden soll. Wird “Zur
                Abgabe” ausgewählt, gilt der Antrag nach dem Weiterleiten als
                abgeschlossen.
              </p>
              <RadioFormField
                on={builder.fields.weiterleitungArt}
                orientation='horizontal'
                items={[
                  {name: 'Zur Ansicht', value: WeiterleitungArt.Kopieren},
                  {name: 'Zur Abgabe', value: WeiterleitungArt.Schliessen},
                ]}
              />
            </>
          )}
        </div>
        <div className='absolute bottom-5 right-5 flex justify-end gap-4 pt-6'>
          <Dialog.Close asChild>
            <Button variant='text-secondary'>Abbrechen</Button>
          </Dialog.Close>
          <Button
            type='submit'
            disabled={buttonDisabled}
            endAdornment={<SVG src={ConvertToText} />}
          >
            Antrag weiterleiten
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
